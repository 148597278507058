<template>
  <!--begin::Work Orders List-->
  <div class="row g-5 gx-xxl-12">
    <ejs-gantt
      :ref="setItemRef"
      id="GanttContainer"
      :dataSource="data"
      :height="height"
      :highlightWeekends="true"
      :taskFields="taskFields"
      :labelSettings="labelSettings"
      :treeColumnIndex="1"
      :workWeek= "workWeek"
      :projectStartDate="projectStartDate"
      :projectEndDate="projectEndDate"
      :dateFormat="dateFormat"
      :timelineSettings="timelineSettings"
      :queryTaskbarInfo="queryTaskbarInfo"
      :editSettings="editSettings"
      :toolbar="toolbar"
      :resourceFields= "resourceFields"
      :resources="resources"
      :editDialogFields="editDialogFields"
      :tooltipSettings="tooltipSettings"
    >
    </ejs-gantt>
  </div>
  <!--end::Work Orders List-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import {
  GanttComponent,
  Selection,
  Edit,
  Toolbar,
  DayMarkers
} from "@syncfusion/ej2-vue-gantt";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";
//import { projectNewData } from "@/data-source";

//import "../../../node_modules/@syncfusion/ej2/bootstrap5.css";
import component from "*.vue";


export const projectNewData: Record<string, any> = [
  {
    TaskID: 1,
    TaskName: 'Eldar Musayev',
    StartDate: new Date('02/01/2022'),
    EndDate: new Date('02/28/2022'),
    subtasks: [
      { TaskID: 2, TaskName: 'EA', StartDate: new Date('02/01/2022'), Duration: 5, Progress: 100 },
      { TaskID: 3, TaskName: 'WA', StartDate: new Date('02/08/2022'), Duration: 3, Progress: 100, Predecessor: '2' },
      { TaskID: 4, TaskName: 'Home', StartDate: new Date('02/11/2022'), Duration: 7, Progress: 99, Predecessor: '3' },
      {
        TaskID: 5, TaskName: 'DWG', StartDate: new Date('02/18/2022'), Duration: 4, Progress: 100, Predecessor: '4'
      },
      {
        TaskID: 6, TaskName: 'Vacation', StartDate: new Date('02/17/2022'), Duration: 8, Progress: 0, Predecessor: '5'
      },
    ]
  },
  {
    TaskID: 7,
    TaskName: 'Emil Rustamov',
    StartDate: new Date('02/01/2022'),
    EndDate: new Date('02/28/2022'),
    subtasks: [
      {
        TaskID: 8, TaskName: 'On shift', StartDate: new Date('02/01/2022'), Duration: 27, Progress: 100
      }
    ]
  },
  {
    TaskID: 9,
    TaskName: 'Oktay Heybatov',
    StartDate: new Date('02/01/2022'),
    EndDate: new Date('03/06/2022'),
    subtasks: [
      {
        TaskID: 10, TaskName: 'Home', StartDate: new Date('02/01/2022'), Duration: 13, Progress: 99
      },
      {
        TaskID: 11, TaskName: 'Vacation', StartDate: new Date('02/14/2022'), Duration: 7, Progress: 0, Predecessor: '10'
      },
      {
        TaskID: 12, TaskName: 'CA', StartDate: new Date('02/21/2022'), Duration: 10, Progress: 100, Predecessor: '11'
      }
    ]
  }
];


export default defineComponent({
  name: "gantt-hcm",
  components: {
    "ejs-gantt": GanttComponent
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  methods: {
    onValueChange: function(args, document) {
      document.getElementById("date_label").textContent =
          "Selected Value: " + args.value.toLocaleDateString();
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    hideInitialColumns: function() {
      const gantt = (this.itemRefs[0] as GanttComponent);
      gantt.hideColumn("ID");
      gantt.hideColumn("Start Date");
      gantt.hideColumn("End Date");
      gantt.hideColumn("Duration");
      gantt.hideColumn("Dependency");
      gantt.hideColumn("Progress");
      //console.log(this.itemRefs);
      //console.log(gantt);
    }
  },
  created: function() {
    setTimeout(() => {
      this.hideInitialColumns();
    }, 500);
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Workforce management calendar");
      setCurrentPageBreadcrumbs("Workforce management calendar", [
        "Human capital management"
      ]);
      setCurrentPageToolbarType("workForceSchedule");
    });
  },
  data: function() {
    const itemRefs: any[] = [];
    return {
      itemRefs,
      data: projectNewData,
      height: "80%",
      workWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      defaultWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      taskFields: {
        id: "TaskID",
        name: "TaskName",
        startDate: "StartDate",
        endDate: "EndDate",
        duration: "Duration",
        progress: "Progress",
        dependency: "Predecessor",
        child: "subtasks"
      },
      editSettings: {
        allowAdding: true,
        allowEditing: true,
        allowDeleting: true,
        allowTaskbarEditing: true,
        showDeleteConfirmDialog: true
      },
      renderBaseline:true,
      toolbar: ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll', 'Indent', 'Outdent'],
      resourceFields: {
        id: 'resourceId',
        name: 'resourceName'
      },
      editDialogFields: [
        { type: 'General', headerText: 'General' },
        { type: 'Dependency' },
        { type: 'Notes' },
      ],
      columnNames: [
        { id: 'TaskID', name: 'ID', visible: false },
        { id: 'TaskName', name: 'Asset', width: 300},
        { id: 'StartDate', name: 'Start Date' },
        { id: 'EndDate', name: 'End Date' },
        { id: 'Duration', name: 'Duration', visible: false },
        { id: 'Predecessor', name: 'Dependency', visible: false },
        { id: 'Progress', name: 'Progress', visible: false }
      ],
      queryTaskbarInfo: function(args) {
        if (args.data.Progress == 99) {
          //home
          args.progressBarBgColor = "#ff9f1c";
          args.taskbarBgColor = "#c17005";
          args.taskbarBorderColor  = "#ccc";
        } else {
          args.progressBarBgColor = "#67981b";
          args.taskbarBgColor = "#a91111";
          args.taskbarBorderColor  = "#ccc";
        }
      },
      colfields: { text: 'name', value: 'id' },
      /*columns: [
        { field: "TaskID", visible: false },
        { field: "TaskName", width: 300 },
        { field: "StartDate" },
        { field: "EndDate" },
        { field: "Duration", visible: false },
        { field: "Predecessor", visible: false },
        { field: "Progress", visible: false }
      ],*/
      labelSettings: {
        taskLabel: "TaskName"
      },
      splitterSettings: {
        columnIndex: 2
      },
      /*timelineSettings: {
        timelineViewMode:'Week',
        weekStartDay:1
      },*/
      timelineSettings: {
        topTier: {
          format: 'MMM, y',
          unit: 'Month'
        },
        bottomTier: {
          unit: 'Day',
          format: 'dd',
          count:1
        }
      },
      tooltipSettings: {
        showTooltip: true,
        connectorLine: false
      },
      projectStartDate: new Date("02/01/2022"),
      projectEndDate: new Date("04/01/2022")
    };
  },
  provide: {
    gantt: [Edit, Selection, Toolbar, DayMarkers]
  }
});
</script>

<style>
#date_label {
  display: block;
  width: 248px;
  color: rgba(0, 0, 0, 0.58);
  margin-left: 5px;
}

.e-gantt .e-gantt-chart .e-connector-line-right-arrow {
  border-left-color: #333 !important;
}

.e-gantt .e-gantt-chart .e-line {
  border-color: #333 !important;
}

.e-task-label {
  display: block !important;
  text-align: center !important;
}

.control_wrapper.calendar-default {
  max-width: 300px;
  margin: 0 auto;
}
body.highcontrast #date_label {
  color: white;
}
</style>
